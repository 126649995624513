<template>
  <el-container height="100%">
    <el-header>
      <el-menu
        :default-active="activeIndex2"
        class="el-menu-demo"
        mode="horizontal"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
        @select="handleSelect"
      >
        <el-menu-item index="1">
          <el-link href="https://www.tzspace.cn"> 主页</el-link>
        </el-menu-item>
        <el-menu-item index="2">
          <el-link href="https://teacher.tzspace.cn">教师入口</el-link>
        </el-menu-item>
        <el-menu-item index="3">
          <el-link href="https://stu.tzspace.cn"> 学生入口</el-link>
        </el-menu-item>
        <el-menu-item index="4">
          <el-link href="https://demo.tzspace.cn">人工智能体验</el-link>
        </el-menu-item>

        <el-sub-menu index="5" color="#FFFFFF">
          <template #title>合作学校</template>
          <el-sub-menu index="5-1" show-timeout="100" hide-timeout="100" v-if="false">
            <template #title>北京师范大学广州实验学校</template>
            <el-sub-menu-item index="5-1-1">
              <el-menu-item>
                <el-link href="/sdsy" underline="false" style="color: lightyellow"> - 学生端 - </el-link>
              </el-menu-item>
            </el-sub-menu-item>

            <el-sub-menu-item index="5-1-1">
              <el-menu-item>
                <el-link href="/sdsy_t" underline="false" style="color: lightyellow"> - 教师端 -</el-link>
              </el-menu-item>
            </el-sub-menu-item>
          </el-sub-menu>
          <el-sub-menu index="5-2" show-timeout="100" hide-timeout="100">
            <template #title>得佳教育</template>
            <el-sub-menu-item index="5-2-1">
              <el-menu-item>
                <el-link href="/dejia/student" underline="false" style="color: lightyellow"> - 学生端 - </el-link>
              </el-menu-item>
            </el-sub-menu-item>

            <el-sub-menu-item index="5-2-2">
              <el-menu-item>
                <el-link href="/dejia/teacher" underline="false" style="color: lightyellow"> - 教师端 -</el-link>
              </el-menu-item>
            </el-sub-menu-item>
          </el-sub-menu>
        </el-sub-menu>
      </el-menu>
    </el-header>
    <el-main>
      <el-carousel pause-on-hover="false" autoplay>
        <el-carousel-item>
          <div class="swiper-slide item idx-1">
            <div class="item-content">
              <div class="title">欢迎使用人工智能教育平台</div>
              <div class="desc">
                本平台帮助教师，学校快速完成人工智能教学课程部署，帮助学生深入扎实的学习人工智能相关课程
              </div>
            </div>
          </div>
        </el-carousel-item>

        <el-carousel-item>
          <div class="swiper-slide item idx-2">
            <div class="item-content">
              <div class="title">轻松教，高效学</div>
              <div class="desc">整合人工智能、大数据和云计算等新技术，服务课前、课中、课后教学场景</div>
            </div>
          </div>

          <h1 class="text-success fs-1">2</h1>
        </el-carousel-item>

        <el-carousel-item>
          <div class="swiper-slide item idx-3">
            <div class="item-content">
              <div class="title">积跬步，至千里</div>
              <div class="desc">以测促教，以考促学，考试大数据助力教育质量的全面监测</div>
            </div>
          </div>
          <h1 class="text-danger fs-1">3</h1>
        </el-carousel-item>

        <el-carousel-item>
          <div class="swiper-slide item idx-4">
            <div class="item-content">
              <div class="title">让教与学释放更多能量</div>
              <div class="desc">基于动态教学数据分析，构建全新的智慧教学环境，全面助力学校实现智慧“教”与“学”。</div>
            </div>
          </div>
          <h1 class="text-danger fs-1">4</h1>
        </el-carousel-item>
      </el-carousel>
    </el-main>
  </el-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',

  components: {},

  data: function () {
    return {}
  }
}
</script>

<style>
html,
body {
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: rgba(255, 255, 255, 0.52);
  text-decoration: none;
}

html,
body,
.wrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  color: #fff;
  background: darkblue;
}

.wrapper {
  overflow-y: auto;
  position: relative;
}

.header {
  position: absolute;
  top: 0;
  width: 100%;
  height: 180px;
  z-index: 999;
  display: flex;
}

.header .erge-nav {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0) !important;
  padding: 0;
}

.header .erge-nav .erge-band .logo {
  width: 100%;
  height: 100%;
  position: relative;
}

.header .erge-nav .erge-band .logo-icon {
  position: absolute;
  bottom: 10%;
  right: 25%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 68px;
  height: 61px;
  background-image: url('~@/assets/images/logo.png');
}

.header .erge-nav .erge-navbar-collapse {
  margin-left: -394px;
  height: 100%;
  flex-grow: 0;
  width: 788px;
}

.header .erge-nav .erge-navbar-collapse ul.navbar-nav {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
}

.header .erge-nav .erge-navbar-collapse ul.navbar-nav .nav-item {
  width: 20%;
  height: 100%;
  float: left;
  cursor: pointer;
  position: relative;
}

.header .erge-nav .erge-navbar-collapse ul.navbar-nav .nav-item a:hover {
  color: #fff;
}

.header .erge-nav .erge-navbar-collapse ul.navbar-nav .nav-item:after {
  display: block;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
}

.header .erge-nav .erge-navbar-collapse ul.navbar-nav .nav-item .label {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 20%;
  color: rgba(255, 255, 255, 0.52);
}

.header .erge-nav .erge-navbar-collapse .navbar-nav:before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
}

.header .erge-nav .sign {
  height: 100%;
  flex-grow: 1;
  position: relative;
}

.header .erge-nav .sign .wrap {
  position: absolute;
  bottom: 20%;
  left: 20%;
}

.header .erge-nav .sign .wrap .sign-in {
  padding-right: 10px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.52);
  text-decoration: none;
}

.el-main {
  margin: 0px;
}

.item {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

.item.idx-1 {
  background: url('~@/assets/images/band/band-slide-1.png');
  background-size: 100% 100%;
}

.item.idx-2 {
  background: url('~@/assets/images/band/band-slide-2.png');
  background-size: 100% 100%;
}

.item.idx-3 {
  background: url('~@/assets/images/band/band-slide-3.png');
  background-size: 100% 100%;
}

.item.idx-4 {
  background: url('~@/assets/images/band/band-slide-4.png');
  background-size: 100% 100%;
}

.item-content {
  width: 100%;
  position: absolute;
  top: 43%;
  transform: translateY(-50%);
}

.item-content .title {
  text-align: center;
  font-size: 45px;
  margin-bottom: 15px;
  letter-spacing: 5px;
  color: white;
}

.item-content .desc {
  text-align: center;
  font-size: 26px;
  color: rgb(220, 255, 225);
}

#app {
  height: 100vh;
  min-height: 100vh;
  background: rgb(63, 63, 78);
}

.el-container {
  height: 100%;
}

.el-carousel,
.el-carousel .el-carousel__container {
  height: 100%;
}
</style>
