<template>
  <el-container>
    <el-header height="100px" class="">
      <sdsy-header class="d-flex flex-row" style="width: 100%; height: 100%"/>
    </el-header>

    <el-main class="sdsy-main">
      <div class="container mt-5 d-flex flex-row justify-content-center">
        <el-card type="box-card" class="login-card" style="width: 600px;">
          <div class="card-header d-flex flex-row justify-content-center align-items-baseline el-header">
            <span class="fw-bold text-white fs-4">{{ schoolName }}教师端登录</span>
          </div>
          <div class="card-body container">
            <form
                action="https://teacher.tzspace.cn/login/authenticate"
                method="POST"
                id="loginForm"
                class="form-signin"
                autocomplete="off"
            >
              <input type="hidden" name="schoolName" value="sdsy"/>
              <input
                  type="text"
                  class="form-control mb-3"
                  name="username"
                  id="username"
                  placeholder="用户名"
                  required=""
                  autofocus=""
                  autocomplete="off"
                  style="
                  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAYAAABSO15qAAAAAXNSR0IArs4c6QAAAPhJREFUOBHlU70KgzAQPlMhEvoQTg6OPoOjT+JWOnRqkUKHgqWP4OQbOPokTk6OTkVULNSLVc62oJmbIdzd95NcuGjX2/3YVI/Ts+t0WLE2ut5xsQ0O+90F6UxFjAI8qNcEGONia08e6MNONYwCS7EQAizLmtGUDEzTBNd1fxsYhjEBnHPQNG3KKTYV34F8ec/zwHEciOMYyrIE3/ehKAqIoggo9inGXKmFXwbyBkmSQJqmUNe15IRhCG3byphitm1/eUzDM4qR0TTNjEixGdAnSi3keS5vSk2UDKqqgizLqB4YzvassiKhGtZ/jDMtLOnHz7TE+yf8BaDZXA509yeBAAAAAElFTkSuQmCC');
                  background-repeat: no-repeat;
                  background-attachment: scroll;
                  background-size: 16px 18px;
                  background-position: 98% 50%;
                  cursor: auto;
                "/>
              <input
                  type="password"
                  class="form-control"
                  name="password"
                  id="password"
                  placeholder="密码"
                  required=""
                  autocomplete="off"
                  style="
                  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAYAAABSO15qAAAAAXNSR0IArs4c6QAAAPhJREFUOBHlU70KgzAQPlMhEvoQTg6OPoOjT+JWOnRqkUKHgqWP4OQbOPokTk6OTkVULNSLVc62oJmbIdzd95NcuGjX2/3YVI/Ts+t0WLE2ut5xsQ0O+90F6UxFjAI8qNcEGONia08e6MNONYwCS7EQAizLmtGUDEzTBNd1fxsYhjEBnHPQNG3KKTYV34F8ec/zwHEciOMYyrIE3/ehKAqIoggo9inGXKmFXwbyBkmSQJqmUNe15IRhCG3byphitm1/eUzDM4qR0TTNjEixGdAnSi3keS5vSk2UDKqqgizLqB4YzvassiKhGtZ/jDMtLOnHz7TE+yf8BaDZXA509yeBAAAAAElFTkSuQmCC');
                  background-repeat: no-repeat;
                  background-attachment: scroll;
                  background-size: 16px 18px;
                  background-position: 98% 50%;
                  cursor: auto;
                "
              />
              <button type="submit" class="mt-5 btn btn-info">登录</button>
            </form>
          </div>
        </el-card>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import {toRefs, reactive} from '@vue/reactivity';

import SdsyHeader from './SdsyHeader'

export default {
  name: "SdsyStudent",

  components: {
    SdsyHeader,
  },

  setup() {
    const data = reactive({
      schoolName: '北京师范大学实验学校'
    })

    return {
      ...toRefs(data)
    };
  },
};
</script>

<style scoped>
html, body {
  background: whitesmoke;
}

.el-container {
  background: white;
}

.el-header {
  background: rgb(84, 60, 146);
  width: 100%;
}

.sdsy-main {
  background-image: url('~@/assets/images/schools/sdsy/bg_student.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
}

.login-card {
  background-color: #d3ecd0;
}
</style>
