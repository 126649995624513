<template>
  <div class="p-3" style="height: 100%; width: 100%">
    <el-image :src="require('@/assets/images/schools/sdsy/logo.png')" fit="contain"
    ></el-image>
  </div>
</template>

<script>
export default {
  name: "SdsyHeader"
}
</script>

<style scoped>

</style>