import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue3 from 'bootstrap-vue-3'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

const vueApp = createApp(App)

vueApp.use(ElementPlus)
vueApp.use(BootstrapVue3)
vueApp.use(router)

vueApp.mount('#app')
