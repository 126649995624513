import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import SdsyStudent from '../views/schools/sdsy/Sdsy.vue'
import SdsyTeacher from '../views/schools/sdsy/Sdsy_t.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/sdsy_t',
    name: 'Sdsy_t',
    component: SdsyTeacher,
    meta: {
      title: '北京师范大学广州实验学校 -- 教师端'
    }
  },
  {
    path: '/sdsy',
    name: 'Sdsy',
    component: SdsyStudent,
    meta: { title: '北京师范大学广州实验学校 -- 学生端' }
  },
  {
    path: '/dejia',
    component: () => import('@/views/schools/dejia/index.vue'),
    children: [
      {
        path: 'teacher',
        name: 'dejia.teacher',
        meta: { title: '德佳教育 -- 教师端' },
        component: () => import('@/views/schools/dejia/Teacher.vue')
      },
      {
        path: 'student',
        name: 'dejia.student',
        meta: { title: '德佳教育 -- 学生端' },
        component: () => import('@/views/schools/dejia/Student.vue')
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
